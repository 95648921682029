import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav, Container} from "react-bootstrap"

import { FaBars, FaYoutube, FaBandcamp } from "react-icons/fa"
import { IoLogoFacebook } from "react-icons/io"

const CustomNavbar = ({ pageInfo }) => {
  return (
    <>
      <Navbar variant="dark" expand="lg" id="site-navbar">
        <Container className="flex-row-reverse">
          <Nav className="ml-auto flex-row external-links">
            <a href="https://www.facebook.com/TheDirteezSwampabilly/" target="_blank" rel="noopener noreferrer">
              <IoLogoFacebook />
            </a>
            <a href="https://www.youtube.com/channel/UCqFhwKKh5Om6pBr0kZICcig?view_as=subscriber" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
            </a>
            <a href="https://dirteez.bandcamp.com/" target="_blank" rel="noopener noreferrer">
              <FaBandcamp />
            </a>
          </Nav>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <FaBars />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
            <Link to="/" className="link-no-style">
                <Nav.Link as="span" eventKey="index">
                  HOME
                </Nav.Link>
              </Link>
              <Link to="/gigs" className="link-no-style">
                <Nav.Link as="span" eventKey="gigs">
                  GIGS
                </Nav.Link>
              </Link>
              <Link to="/music" className="link-no-style">
                <Nav.Link as="span" eventKey="music">
                  MUSIC
                </Nav.Link>
              </Link>
              <Link to="/releases" className="link-no-style">
                <Nav.Link as="span" eventKey="releases">
                  RELEASES
                </Nav.Link>
              </Link>
              <Link to="/gallery" className="link-no-style">
                <Nav.Link as="span" eventKey="gallery">
                  GALLERY
                </Nav.Link>
              </Link>
              <Link to="/contact" className="link-no-style">
                <Nav.Link as="span" eventKey="contact">
                  CONTACT
                </Nav.Link>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default CustomNavbar
