import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo.png"
import { Container } from "react-bootstrap"

const Header = ({ siteTitle }) => (
  <header
    id="main-header"
  >
    <Container>
      <h1>
        <Link
          to="/"
        >
          <img src={logo} className="img-fluid" alt="The Dirteez" />
        </Link>
      </h1>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
