/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

import Header from "./header"
import Navbar from "./navBar"

const Layout = ({ children, pageInfo }) => {
  var pageName = '';
  if(pageInfo) {
    pageName = pageInfo.pageName;
  }
  return (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className={pageName + " px-0 main"}>
          <Row noGutters>
            <Col>
              <Header siteTitle={data.site.siteMetadata.title} />
            </Col>
          </Row>
          <Navbar pageInfo={pageInfo} />
          <div id="main-content">
            <Row noGutters>
              <Col>
                  <main>{children}</main>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    )}
  />
)}

export default Layout
